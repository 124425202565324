import { useProgress } from "@react-three/drei";
import { useEffect } from "react";
import "./style.css";

export const LoadingScreen = (props) => {
  const { started, setStarted } = props;
  const { progress, total, loaded, item } = useProgress();

  useEffect(() => {
    console.log(progress, total, loaded, item);
    if (progress === 100) {
      setTimeout(() => {
        setStarted(true);
      }, 500);
    }
  }, [progress, total, loaded, item]);

  return (
    <>
      <div
        className={`loading 
  ${started ? "noOpacity" : "yOpacity"}`}
      >
        <div className="textOne">
          <div
            className="textTwo"
            style={{
              width: `${progress}%`,
            }}
          >
            Breeona Day
          </div>
          <div className="opFourty">Breeona Day</div>
        </div>
      </div>
    </>
  );
};
