import {
  Text,
  Html,
  ContactShadows,
  PresentationControls,
  Float,
  Environment,
  useGLTF,
} from "@react-three/drei";
import { useState, useEffect } from "react";

export default function Experience() {
  const computer = useGLTF("Macbook/model.gltf");
  const phone = useGLTF("iPhone/model.gltf");
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth < 1024
  );

  const [notPhone, setSize] = useState([0.375, 1.633, -1.8]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1024);
      setSize(window.innerWidth > 500);
    }

    if (typeof window !== "undefined") {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      // remove event listener when the component is unmounted to not cause any memory leaks
      // otherwise the event listener will continue to be active
      window.removeEventListener("resize", handleResize);
    };
    // add `isMobile` state variable as a dependency so that
    // it is called every time the window is resized
  }, [isMobile]);

  // console.log(isMobile);
  if (isMobile) {
    if (notPhone) {
      //For small screen
      return (
        <>
          <color args={["#191f70"]} attach="background" />
          <Environment preset="city" />
          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={"#ff6900"}
            rotation={[-0.1, Math.PI, 0]}
            position={[0, 0.55, -3.15]}
          />
          <primitive
            object={phone.scene}
            position-y={-1.55}
            rotation-x={-0.35}
            rotation-y={-0.45}
            rotation-z={-0.1}
          >
            <Html
              transform
              wrapperClass="phoneScreen"
              distanceFactor={1.192}
              position={[0.48, 1.325, -1.8]}
              rotation-x={-0.0355}
              rotation-y={0.008}
              rotation-z={-0.008}
            >
              <iframe src="https://breeonaday.com/" />
            </Html>
          </primitive>
          <Float rotationIntensity={0.05}>
            <Text
              font="./bangers-v20-latin-regular.woff"
              fontSize={0.4}
              position={[0.25, 1.62, 0.5]}
              rotation-y={-1.1}
              rotation-x={0.2}
              maxWidth={2}
            >
              BREE DAY
            </Text>
          </Float>
        </>
      );
    }
    //For Mobile Phone
    else {
      return (
        <>
          <color args={["#191f70"]} attach="background" />
          <Environment preset="city" />
          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={"#ff6900"}
            rotation={[-0.1, Math.PI, 0]}
            position={[0, 0.55, -3.15]}
          />
          <primitive
            object={phone.scene}
            position-y={-1.55}
            rotation-x={-0.35}
            rotation-y={-0.45}
            rotation-z={-0.075}
          >
            <Html
              transform
              wrapperClass="phoneScreen"
              distanceFactor={1.192}
              position={[0.375, 1.633, -1.8]}
              // position={[0.48, 1.325, -1.8]}
              rotation-x={-0.03}
              rotation-y={0.009}
              rotation-z={-0.008}
            >
              <iframe src="https://breeonaday.com/" />
            </Html>
          </primitive>
          <Float rotationIntensity={0.02}>
            <Text
              font="./bangers-v20-latin-regular.woff"
              fontSize={0.4}
              position={[0.1, 1.6, 0.5]}
              rotation-y={-1}
              rotation-x={0.3}
              maxWidth={2}
            >
              BREE DAY
            </Text>
          </Float>
        </>
      );
    }
  } else {
    return (
      <>
        //Desktop
        <color args={["#191f70"]} attach="background" />
        <Environment preset="city" />
        <rectAreaLight
          width={2.5}
          height={1.65}
          intensity={65}
          color={"#ff6900"}
          rotation={[-0.1, Math.PI, 0]}
          position={[0, 0.55, -3.15]}
        />
        <PresentationControls
          global
          rotation={[0.23, 0.01, 0.1]}
          polar={[-0.4, 0.2]}
          azimuth={[-1, 0.75]}
          config={{ mass: 2, tension: 400 }}
          snap={{ mass: 4, tension: 400 }}
        >
          <Float rotationIntensity={0.4}>
            <rectAreaLight
              width={2.5}
              height={1.65}
              intensity={65}
              color={"#ff6900"}
              rotation={[-0.1, Math.PI, 0]}
              position={[0, 0.55, -3.15]}
            />

            <primitive
              object={computer.scene}
              position-y={-1.2}
              // rotation-x={ 0.13 }
            >
              <Html
                transform
                wrapperClass="htmlScreen"
                distanceFactor={1.17}
                position={[0, 1.56, -1.4]}
                rotation-x={-0.256}
              >
                <iframe src="https://breeonaday.com/" />
              </Html>
            </primitive>

            <Text
              font="./bangers-v20-latin-regular.woff"
              fontSize={1}
              position={[2, 0.75, 0.75]}
              rotation-y={-1.25}
              maxWidth={2}
            >
              BREE DAY
            </Text>
          </Float>
        </PresentationControls>
        <ContactShadows position-y={-1.4} opacity={0.4} scale={5} blur={2.4} />
      </>
    );
  }
}
