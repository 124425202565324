import React from "react";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.jsx";
import { LoadingScreen } from "./LoadingScreen";
import { Suspense, useEffect, useState } from "react";
import "./style.css";

function App() {
  const [started, setStarted] = useState(false);
  console.log(started);
  return (
    <>
      <LoadingScreen started={started} setStarted={setStarted} />
      <Canvas
        camera={{
          fov: 45,
          near: 0.1,
          far: 2000,
          position: [-3, 1.5, 4],
        }}
      >
        <Suspense>
          <Experience />
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
